import {useEffect} from 'react';
import {useChatContext} from "./ChatContext";
import useAxiosRequest, {GET} from "../../../hooks/useAxiosRequest";
import {selectChatContactId} from "../../../store/slices/authSlice";
import {useSelector} from "react-redux";
import {getEchoInstance} from "../../../services/pusherEchoInstance";
import {enqueueSnackbar} from "notistack";
import useSoundPlayer from "../../../hooks/useSoundPlayer";

const ChatManager = ({children, mobile = false}) => {
    const axiosRequest = useAxiosRequest();
    const userContactId = useSelector(selectChatContactId);
    const branch = useSelector((state) => state.nav.selectedBranch);
    const { setMessagesForChat, setLoading, activeChatId, setActiveChatId, getChats, setChats, getRefreshChatsFlag, setRefreshChatsFlag} = useChatContext();
    const {playNewMessageSound} = useSoundPlayer();
    const echoInstance = getEchoInstance( (state) => {
        if( state === "connected")
        {
            console.log('echo connected, refreshing chats');
            setRefreshChatsFlag(Date.now());
        }
    });

    const chats = getChats();

    useEffect(() => {
        if (!userContactId) return;
        if (!branch) return;

        setLoading(true);

        const [request] = axiosRequest(GET, '/api/chat/get-chats', {
            branch_code: branch.code,
            contact_id: userContactId ?? null
        });
        request.then((response) => {
            console.log('get chats', response);
            setChats(response);
            setLoading(false);
        });
    }, [axiosRequest, branch, setChats, userContactId, getRefreshChatsFlag, setLoading]);

    useEffect(() => {
        if (!branch) return;

        const channelName2 = `branch.${branch.code}.delete-chat`;

        echoInstance.private(channelName2)

            .listen('DeleteChatMessage', (e) => {
                e.chat_id = parseInt(e.chat_id);

                console.log('delete chat message', e);

                if (activeChatId === e.chat_id)
                    setActiveChatId(null);

                setChats((prevChats) => {
                    return prevChats.filter(chat => chat.id !== e.chat_id);
                });
            })
            .error((e) => {
                console.log('error', e);
            });

        return () => {
            echoInstance.leave(channelName2);
        };
    }, [activeChatId, branch, echoInstance, setActiveChatId, setChats]);

    useEffect(() => {
        if (!branch) return;

        const channelName = `branch.${branch.code}.chat-owned`;

        echoInstance.channel(channelName)

            .listen('ChatOwnedMessage', (e) => {

                console.log('own chat message', e);

                setChats((prevChats) => {
                    return prevChats.map(chat => chat.id === e.chat_id ? {
                        ...chat,
                        unassigned: false,
                        participating: true
                    } : chat);
                });
            })
            .error((e) => {
                console.log('error', e);
            });

        return () => {
            echoInstance.leave(channelName);
        };
    }, [branch, echoInstance, setChats]);

    useEffect(() => {
        if (!branch) return;

        const channelName = `branch.${branch.code}.start-chat`;

        console.log('joining ' + channelName);

        echoInstance.private(channelName)
            .listen('StartChatMessage', (e) => {
                console.log('start chat message', e);
                //setMessages((prevMessages) => [...prevMessages, e.message]);
                const isNewUnssignedChat = e.chat.chat_group_members.some(participant => participant.contact_id === e.chat.owner_contact_id) && e.chat.chat_group_members.length === 1;
                const amParticipating = e.chat.chat_group_members.some(participant => participant.contact_id === userContactId);
                if (!e.chat.private || amParticipating || isNewUnssignedChat || (e.chat.owner_contact_id === userContactId))
                    setChats((prevChats) => {
                        const exists = prevChats.some(room => room.id === e.chat.id);

                        if (!exists) { // && !mobile) {
                            enqueueSnackbar(`New chat request from ${e.chat.from_chat_name}`, {variant: 'info'});
                            playNewMessageSound();
                        }

                        const newChat = {
                            id: e.chat.id,
                            chat_name: e.chat.owner_contact_id === userContactId ? e.chat.to_chat_name : e.chat.from_chat_name,
                            last_message: "",
                            last_message_time: e.chat.updated_at,
                            private: e.chat.private,
                            participating: amParticipating,
                            unassigned: isNewUnssignedChat,
                            unread_count: 0,
                            other_members: e.chat.chat_group_members.map(member => member.contact_id).join(','),
                        };

                        return exists
                            ? prevChats.map(chat => chat.id === e.chat.id ? {...chat, ...newChat} : chat)
                            : [...prevChats, newChat];
                    });
            })
            .error((e) => {
                console.log('error', e);
            });

        return () => {
            echoInstance.leave(channelName);
        };
    }, [activeChatId, branch, echoInstance, mobile, playNewMessageSound, setActiveChatId, setChats, userContactId]);

    useEffect(() => {

        if (!branch || !echoInstance || !chats) return;

        const subscriptions = chats.map(chat => {
            let channelName = `private.${branch.code}.${chat.id}`;
            echoInstance.private(channelName)
                .listen('ChatMessage', (e) => {
                    console.log('hsp', chat);
                    if( e.message.sender_contact_id !== userContactId) // don't make a sound for messages you send
                        playNewMessageSound();

                    setMessagesForChat(chat.id, (prevMessages) => {
                        const existingOptimisticIndex = prevMessages.findIndex((m) => m.optimistic_id === e.optimistic_id);
                        const isExistingMessage = prevMessages.some((m) => m.id === e.message.id);
                        // replace optimistic message with real message
                        if (existingOptimisticIndex !== -1) {
                            const updatedMessages = [...prevMessages]; // Create a copy of the array
                            updatedMessages[existingOptimisticIndex] = e.message; // Modify the copy
                            return updatedMessages;
                        }

                        if (isExistingMessage) return prevMessages;

                        return [...prevMessages, e.message]
                    });

                    if (activeChatId !== chat.id) {
                        //setUnreadCount((prevUnreadCount) => prevUnreadCount + 1);
                        // find this chat by id and update its last message and last message time
                        setChats((prevChats) => {
                            const newChat = {
                                ...chat,
                                last_message: e.message.message,
                                last_message_time: e.message.created_at,
                                unread_count: chat.unread_count + 1
                            };

                            return prevChats.map((c) => c.id === chat.id ? newChat : c);
                        });
                    } else {
                        // set the last_message of the related chat
                        setChats((prevChats) => {
                            return prevChats.map((c) => c.id === chat.id ? {
                                ...c,
                                last_message: e.message.message,
                                last_message_time: e.message.created_at
                            } : c);
                        });

                        if( e.message.sender_contact_id !== userContactId) // don't mark messages you send as read
                        {
                            axiosRequest(GET, '/api/chat/read', {
                                contact_id: userContactId,
                                message_id: e.message.id
                            }, null, true);
                        }
                    }
                });
            return channelName;
        });

        return () => {
            // Cleanup function to unsubscribe from all channels
            subscriptions.forEach(subscription => {
                //const channelName = `private.${branch.code}.${subscription.chat.id}`;
                echoInstance.leave(subscription);
            });
        };
    }, [activeChatId, axiosRequest, branch, chats, echoInstance, playNewMessageSound, setChats, setMessagesForChat, userContactId]);

    console.log("Rendering ChatManager", chats);

    return {...children};
}

export default ChatManager;
