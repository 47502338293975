import { useSelector } from "react-redux";
import {
    Box,
    Button,
    Typography,
    Modal,
    Backdrop,
    Fade,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    CircularProgress,
    Card,
    CardContent,
} from "@mui/material";
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../hoc/LoadingSpinner";
import useAxiosRequest, { GET } from "../../hooks/useAxiosRequest";
import PendingActionsIcon from '@mui/icons-material/PendingActions';

const CustomModal = ({ open, handleClose, title, children }) => (
    <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
    >
        <Fade in={open}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    maxWidth: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 2,
                    borderRadius: 2,
                }}
            >
                <Typography variant="h6" gutterBottom>
                    {title}
                </Typography>
                {children}
            </Box>
        </Fade>
    </Modal>
);

function BankDetails( {data}) {
    const user = useSelector((state) => state.auth.user);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const axiosRequest = useAxiosRequest();
    const { enqueueSnackbar } = useSnackbar();

    const bankData = data.audit;

    const handleModalClose = () => {
        navigate("/");
    };

    const renderValueChange = (key, value, valueTo) => {
        return value !== valueTo ? (
            <span>
                <s>{value}</s> &rarr; <span>{valueTo}</span>
            </span>
        ) : (
            <span>{value}</span>
        );
    };

    return (
        <LoadingSpinner flag={loading}>
            <Box>
                <Typography variant="h6" gutterBottom align="center">
                    Bank Details Verification
                </Typography>
                <Stack direction="column" alignItems="center" justifyContent="center" spacing={0.5}>
                    <PendingActionsIcon color="primary" fontSize="large" />
                    <Typography variant="body2" textAlign="center">
                        Your bank details verification is currently in progress.
                    </Typography>
                </Stack>
                {bankData ? (
                    <Card variant="outlined" sx={{ boxShadow: 0, mb: 1 }}>
                        <CardContent>
                            <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
                                <Table size="small" aria-label="bank details table">
                                    <TableBody>
                                        {[
                                            { label: "Account Name", key: "account_name" },
                                            { label: "Branch Code", key: "branch_code" },
                                            { label: "Account Number", key: "account_number" },
                                            { label: "Address Line 1", key: "address1" },
                                            { label: "Address Line 2", key: "address2" },
                                            { label: "Suburb", key: "suburb" },
                                            { label: "Town", key: "town" },
                                            { label: "Post Code", key: "postcode" },
                                        ].map(({ label, key }) => (
                                            <TableRow key={key}>
                                                <TableCell sx={{ fontWeight: 'bold', fontSize: "10px", width: '40%', p: 0.5 }}>
                                                    {label}
                                                </TableCell>
                                                <TableCell sx={{ p: 0.5 }}>
                                                    {renderValueChange(
                                                        label,
                                                        bankData[key],
                                                        bankData[`${key}_to`]
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                        <CircularProgress size={24} />
                    </Box>
                )}
                <Box sx={{ mt: 2 }}>
                    <Button
                        fullWidth
                        color="secondary"
                        variant="contained"
                        onClick={handleModalClose}
                        sx={{ p: 1 }}
                    >
                        Close
                    </Button>
                </Box>
            </Box>
        </LoadingSpinner>
    );
}

export default BankDetails;
