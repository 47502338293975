import { TextField } from "@mui/material";
import React from "react";

const MaskFormikTextField = ({ onChange, inputMask = /./, uppercase = false, ...props }) => {
    const handleChange = (e) => {
        const newValue = uppercase ? e.target.value.toUpperCase() : e.target.value;
        // Filter out any characters that don't match the inputMask
        const filteredValue = Array.from(newValue)
            .filter(char => inputMask.test(char))
            .join('');

        if (onChange) {
            // Create a synthetic event to pass to Formik
            const syntheticEvent = {
                ...e,
                target: {
                    ...e.target,
                    value: filteredValue,
                    name: e.target.name, // Ensure the name is included
                },
            };
            onChange(syntheticEvent);
        }
    };

    return (
        <TextField
            {...props}
            onChange={handleChange}
            type="text"
            InputLabelProps={{ shrink: true }}
            autoComplete="off"
            InputProps={{
                size: 'small',
            }}
        />
    );
};

export default MaskFormikTextField;
