import React, {lazy, Suspense, useContext, useEffect, useState} from 'react';
import {
    AppBar, Badge,
    BottomNavigation,
    BottomNavigationAction,
    Box,
    Grid,
    IconButton,
    Paper,
    Toolbar,
    Typography
} from '@mui/material';
import Home from "../base/Home";
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {useDispatch, useSelector} from "react-redux";
import sdkContext from "../../../telegramSdk/sdkContext";
import {postEvent} from "../../../telegramSdk/postEvent";
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import BranchSelector from "../../../components/BranchSelector";
import TodaysShifts from "../../../components/TodaysShifts";
import ConnectionIndicator from "../../../services/ConnectionIndicator";
import {pusherConnectedSignal} from "../../../services/pusherEchoInstance";
import blue from "../../../assets/images/blue.svg";
import orange from "../../../assets/images/orange.svg";
import green from "../../../assets/images/green.svg";
import OnDuty from "./OnDuty";
import {signal} from "@preact/signals-react";
import {useChatContext} from "../../../components/shared/chat/ChatContext";
import ErrorBoundary from "../../../hoc/ErrorBoundary";
import {version} from "../../../config/version";
import Invoice from "../../../components/invoice/Invoice";
import Invoices from "../../../components/invoice/Invoices";
import {useMessageContext} from "../../../components/messageContext/MessageContext";
import BankDetails from "../../../components/bankDetails/BankDetails";

const ChatComponent = lazy(() => import("../../../components/ChatComponent"));
const ShiftSelectionComponent = lazy(() => import("../../../components/ShiftSelectionComponent"));
const NotificationsList = lazy(() => import("../../../components/notifications/NotificationList"));
const Performance = lazy(() => import("../../../components/Performance"));

export const MENU_HOME = 0;

export const GREEN = 'green';
export const ORANGE = 'orange';
export const backgroundColorSignal = signal(GREEN);

function RenderRoutes( {user} ) {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Box sx={{
                overflow: "hidden"
            }}>
                <Routes>
                    <Route path="/apply" element={<ShiftSelectionComponent mode={"apply"}/>}/>
                    <Route path="/shifts" element={<ShiftSelectionComponent mode={"view"}/>}/>
                    <Route path="/adhoc" element={<ShiftSelectionComponent mode={"adhoc"}/>}/>
                    <Route path="/bank-details" element={ <BankDetails/>}/>
                    <Route path="/pro-forma-invoices" element={<Invoice/>}/>
                    <Route path="/invoices" element={<Invoices/>}/>
                    <Route path="/performance" element={<Performance/>}/>
                    <Route path="/notifications" element={<NotificationsList/>}/>
                    <Route path="/on-duty" element={<OnDuty/>}/>
                    <Route path="/chat" element={<ChatComponent/>}/>
                    <Route path="*" element={<Home/>}/>
                </Routes>
            </Box>
        </Suspense>
    )
}

function Layout() {
    const [currentBottomNav, setCurrentBottomNav] = useState(0);
    const user = useSelector(state => state.auth.user);
    const context = useContext(sdkContext);
    const navigate = useNavigate();
    const location = useLocation();
    const {unreadCount, unreadCountSignal} = useChatContext();
    const {messages } = useMessageContext();
    const {hydrateMessages} = useMessageContext();

    const isHome = location.pathname === '/';

    useEffect(() => {
        if (!user || !isHome) return;
        //hydrateMessages();
    }, [user, isHome]);

    function doLogout() {
        postEvent(context, 'web_app_close');
    }

    return (
        <Box sx={{
            pb: 7,
        }}>
            <AppBar position="fixed" color="default" elevation={0} sx={{
                backgroundImage: `url(${blue})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="menu">
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" style={{flexGrow: 1}}>
                        TDT Self-Services <span style={{fontSize: "0.7rem"}}>{version}</span>
                    </Typography>
                    <IconButton color="inherit">
                        <Typography variant="h6" style={{flexGrow: 1}}>
                            {!!user ? `${user.name} ${user.surname}` : 'Not logged in'}
                        </Typography>
                        <AccountCircle/>
                        <ConnectionIndicator isConnected={pusherConnectedSignal}/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Toolbar/> {/* This adds spacing for the fixed AppBar so content starts below it */}
            <Box sx={{overflow: 'auto', flexGrow: 1, padding: 2}}>
                <Grid item container spacing={2} sx={{padding: 2, overflow: 'auto', flexGrow: 1}}>
                    {(!!user && isHome) && <Grid item xs={12}>
                        <Paper elevation={3} sx={{
                            padding: 2,
                            backgroundImage: `url(${backgroundColorSignal.value === 'green' ? green : backgroundColorSignal.value === 'orange' ? orange : blue})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center'
                        }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} container>
                                    <Grid item xs={12} md={5} alignSelf={"center"}>
                                        <BranchSelector/>
                                    </Grid>
                                    <Grid item xs={12} md={7} alignSelf={"center"} sx={{textAlign: "center"}}>
                                        <ErrorBoundary user={user}>
                                            <TodaysShifts/>
                                        </ErrorBoundary>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>}
                    <Grid item xs={12}>
                        <RenderRoutes user={user}/>
                    </Grid>
                </Grid>
            </Box>
            <BottomNavigation
                value={currentBottomNav}
                onChange={(event, newValue) => {
                    setCurrentBottomNav(newValue);
                }}
                showLabels
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundImage: `url(${blue})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }} // Fix BottomNavigation to the bottom
            >
                <BottomNavigationAction label="Home" icon={<HomeIcon/>} onClick={() => navigate('/')}/>
                { /*<BottomNavigationAction label="Chat"
                                        icon={<Badge color="secondary"
                                                     badgeContent={unreadCount.value}><ChatIcon/></Badge>}
                                        onClick={() => navigate('/chat')}/> */ }
                <BottomNavigationAction label="Notifications"
                                        icon={<Badge color="secondary" badgeContent={messages.length}><NotificationsIcon/></Badge>}
                                        onClick={() => navigate('/notifications')}/>
                <BottomNavigationAction label="Logout" icon={<LogoutIcon/>} onClick={doLogout}/>
            </BottomNavigation>
        </Box>
    );
}

export default Layout;
