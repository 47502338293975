import axios from "../store/tokenAxios/tokenAxios";
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import {CancelToken, isCancel} from "axios";
import {useCallback} from "react";
import {setError, setPage} from "../store/slices/navSlice";
import Helpers from "../components/Helpers";
import {logout} from "../store/slices/authSlice";

export const POST = 'post';
export const GET = 'get';
export const PUT = 'put';
export const DELETE = 'delete';

const useAxiosRequest = () => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    return useCallback((verb, url, payload, successMessage = null) => {
        const source = CancelToken.source();
        payload = (verb === GET || verb === DELETE) ?
            { params: payload, cancelToken: source.token } :
            { ...payload, cancelToken: source.token };

        const requestPromise = new Promise((resolve, reject) => {
            axios[verb](url, payload)
                .then(response => {
                    if (successMessage) {
                        enqueueSnackbar(successMessage, { variant: 'success' });
                    }
                    resolve(response.data);
                })
                .catch(error => {
                    if (isCancel(error)) {
                        console.log('Request canceled', error.message);
                    } else {
                        const errorMessage = Helpers.parseError(error);
                        if( error.response.status === 401 ) {
                            dispatch(logout());
                            dispatch(setPage('auth'));
                        }
                        else dispatch(setError(errorMessage));
                        reject(error);
                    }
                });
        });

        // Return the promise and a function to cancel the request
        return [requestPromise, () => source.cancel('Operation canceled by the user.')];
    }, [dispatch, enqueueSnackbar]);
};

export default useAxiosRequest;
