import { useSelector } from "react-redux";
import {
    Box,
    Button,
    Typography,
    Modal,
    Backdrop,
    Fade, Stack,
} from "@mui/material";
import { useSnackbar } from 'notistack';
import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../hoc/LoadingSpinner";
import useAxiosRequest, {GET, POST} from "../../hooks/useAxiosRequest";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import MaskFormikTextField from "../form/MaskFormkTextField";

const FormField = ({ name, label, inputMask, uppercase, disabled }) => (
    <Box sx={{ mb: 2 }}>
        <Field name={name}>
            {({ field, meta }) => (
                <MaskFormikTextField
                    {...field}
                    inputMask={inputMask}
                    uppercase={uppercase}
                    label={label}
                    variant="outlined"
                    fullWidth
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    disabled={disabled}
                />
            )}
        </Field>
    </Box>
);

const CustomModal = ({ open, handleClose, title, children }) => (
    <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
    >
        <Fade in={open}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    maxWidth: 800,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography variant="h6" gutterBottom>
                    {title}
                </Typography>
                {children}
            </Box>
        </Fade>
    </Modal>
);

function BankDetailsChange( {data}) {
    const user = useSelector((state) => state.auth.user);
    const branch = useSelector((state) => state.nav.selectedBranch);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(true);
    const [verificationModalOpen, setVerificationModalOpen] = useState(false);
    const [bankDetailsSubmitted, setBankDetailsSubmitted] = useState(false);
    const [auditId, setAuditId] = useState(null);
    const navigate = useNavigate();
    const axiosRequest = useAxiosRequest();
    const { enqueueSnackbar } = useSnackbar();

    const bankData = data.current;

    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            account_name: Yup.string().required("Account Name is required"),
            branch_code: Yup.string()
                .matches(/^\d+$/, "Branch Code must be numeric")
                .required("Branch Code is required"),
            account_number: Yup.string()
                .matches(/^\d+$/, "Account Number must be numeric")
                .required("Account Number is required"),
            address1: Yup.string().required("Address Line 1 is required"),
            address2: Yup.string(),
            suburb: Yup.string().required("Suburb is required"),
            town: Yup.string().required("Town is required"),
            postcode: Yup.string()
                .matches(/^\d+$/, "Post Code must be numeric")
                .length(4, "Post Code must be 4 digits")
                .required("Post Code is required"),
        });
    }, []);

    const otpValidationSchema = Yup.object().shape({
        otp: Yup.string()
            .matches(/^\d{6}$/, "OTP must be 6 digits")
            .required("OTP is required"),
    });

    const handleBankDetailsSubmit = (values, { setSubmitting }) => {
        const [request] = axiosRequest(POST, "/driver-api/operations/bank-details", {
            driver_id: user.id,
            branch_id: branch.id,
            ...values
        });
        request.then((response) => {
            enqueueSnackbar(response.message, { variant: "success" });
            setAuditId(response.audit_id);
            setBankDetailsSubmitted(true);
        })
            .catch((error) => {
                enqueueSnackbar("Failed to update bank details", { variant: "error" });
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleOtpSubmit = (values, { setSubmitting }) => {
        const [request] = axiosRequest(POST, "/driver-api/operations/bank-details-otp", {
            audit_id: auditId,
            otp: values.otp
        });

        request.then((response) => {
            if (response.code !== 200) {
                enqueueSnackbar("Invalid OTP. Please check your SMS and try again.", { variant: "error" });
            } else {
                enqueueSnackbar("OTP verified successfully.", { variant: "success" });
                setVerificationModalOpen(true);
            }
        })
            .catch((error) => {
                enqueueSnackbar("An error occurred validating your OTP. Please try again.", { variant: "error" });
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleModalAgree = () => {
        setOpenModal(false);
    };

    const handleModalClose = () => {
        navigate("/");
    };

    const handleVerificationModalClose = () => {
        setVerificationModalOpen(false);
        navigate("/");
    };

    return (
        <LoadingSpinner flag={loading}>
            <CustomModal
                open={openModal}
                handleClose={handleModalClose}
                title="Important Information"
            >
                <Typography variant="body1" color={"secondary"} gutterBottom>Security measures are in place to prevent fraudulent changes to your banking details</Typography>
                <Box sx={{ mt: 3 }}>
                    <ul>
                        <li>Bank account details need to be verified after changing.</li>
                        <li>Changes take 4 days to verify and are subject to a nominal admin fee.</li>
                        <li>Changes may result in payment being delayed during verification.</li>
                    </ul>
                </Box>
                <Stack direction={"column"} spacing={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleModalAgree}
                    >
                        Agree
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleModalClose}
                    >
                        Cancel
                    </Button>
                </Stack>

            </CustomModal>
            <Box sx={{ p: 2 }}>
                { bankDetailsSubmitted ?
                    <Typography variant="h5" gutterBottom>
                        Please enter the OTP sent to your registered mobile number
                    </Typography>
                    :
                    <Typography variant="h5" gutterBottom>
                        Please make sure your details are correct to ensure timely payment
                    </Typography>
                }
                {bankData !== null && (
                    <Formik
                        initialValues={bankDetailsSubmitted ? { otp: "" } : {
                            account_name: bankData.account_name || "",
                            branch_code: bankData.branch_code || "",
                            account_number: bankData.account_number || "",
                            address1: bankData.address1 || "",
                            address2: bankData.address2 || "",
                            suburb: bankData.suburb || "",
                            town: bankData.town || "",
                            postcode: bankData.postcode || "",
                        }}
                        validationSchema={bankDetailsSubmitted ? otpValidationSchema : validationSchema}
                        onSubmit={bankDetailsSubmitted ? handleOtpSubmit : handleBankDetailsSubmit}
                        enableReinitialize
                    >
                        {({ isSubmitting, dirty, isValid }) => (
                            <Form>
                                {!bankDetailsSubmitted && (
                                    <>
                                        <Typography variant="h6" gutterBottom>
                                            Bank Account for Payments
                                        </Typography>
                                        <FormField
                                            name="account_name"
                                            label="Account Name"
                                            inputMask={/[\sA-Za-z0-9-]/}
                                            disabled={bankDetailsSubmitted}
                                        />
                                        <FormField
                                            name="branch_code"
                                            label="Branch Code"
                                            inputMask={/[0-9]/}
                                            disabled={bankDetailsSubmitted}
                                        />
                                        <FormField
                                            name="account_number"
                                            label="Account Number"
                                            inputMask={/[0-9]/}
                                            disabled={bankDetailsSubmitted}
                                        />
                                        <Typography variant="h6" gutterBottom>
                                            Address Details for Invoices
                                        </Typography>
                                        <FormField
                                            name="address1"
                                            label="Address Line 1"
                                            disabled={bankDetailsSubmitted}
                                        />
                                        <FormField
                                            name="address2"
                                            label="Address Line 2"
                                            disabled={bankDetailsSubmitted}
                                        />
                                        <FormField
                                            name="suburb"
                                            label="Suburb"
                                            disabled={bankDetailsSubmitted}
                                        />
                                        <FormField
                                            name="town"
                                            label="Town"
                                            disabled={bankDetailsSubmitted}
                                        />
                                        <FormField
                                            name="postcode"
                                            label="Post Code"
                                            inputMask={/[0-9]/}
                                            disabled={bankDetailsSubmitted}
                                        />
                                    </>
                                )}
                                {bankDetailsSubmitted && (
                                    <FormField
                                        name="otp"
                                        label="OTP"
                                        inputMask={/[0-9]/}
                                        disabled={false}
                                    />
                                )}
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    disabled={isSubmitting || !(dirty && isValid)}
                                    sx={{ mb: 2 }}
                                >
                                    {bankDetailsSubmitted ? "Verify OTP" : "Submit Request"}
                                </Button>
                                <Button
                                    fullWidth
                                    color={"secondary"}
                                    variant="contained"
                                    onClick={() => navigate("/")}
                                >
                                    Cancel
                                </Button>
                            </Form>
                        )}
                    </Formik>
                )}
            </Box>
            <CustomModal
                open={verificationModalOpen}
                handleClose={handleModalClose}
                title="Your change request has been submitted successfully."
            >
                <Typography variant="body1" gutterBottom>
                    For your security, we need to verify your bank account details.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Please hand in the following documents at the {branch.name} office.
                </Typography>
                <ul>
                    <li>Proof of bank account information.</li>
                    <li>Proof of address.</li>
                </ul>
                <Box sx={{ mt: 3 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleVerificationModalClose}
                    >
                        OK
                    </Button>
                </Box>
            </CustomModal>
        </LoadingSpinner>
    );
}

export default BankDetailsChange;
