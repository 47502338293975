import { useSelector } from "react-redux";
import { useSnackbar } from 'notistack';
import React, { useEffect, useState, useMemo } from "react";
import useAxiosRequest, {GET, POST} from "../../hooks/useAxiosRequest";
import BankDetailsChange from "./BankDetailsChange";
import VerificationInProgress from "./VerificationInProgress";

function BankDetails() {
    const user = useSelector((state) => state.auth.user);
    const branch = useSelector((state) => state.nav.selectedBranch);
    const axiosRequest = useAxiosRequest();
    const { enqueueSnackbar } = useSnackbar();
    const [bankData, setBankData] = useState(null);

    useEffect(() => {
        if (user === null) return;
        if (branch === null) return;

        const [request, cancelRequest] = axiosRequest(
            GET,
            `/driver-api/operations/bank-details`,
            {
                driver_id: user.id,
                branch_id: branch.id
            }
        );

        request
            .then((response) => {
                setBankData(response);
            })
            .catch((error) => {
                enqueueSnackbar("Failed to fetch bank details", { variant: "error" });
            });

        return () => {
            cancelRequest();
        };
    }, [user, axiosRequest, enqueueSnackbar]);

    return (
        <>
            { bankData && !bankData.audit && <BankDetailsChange data={bankData}/> }
            { bankData && bankData.audit && <VerificationInProgress data={bankData}/> }
        </>
    );
}

export default BankDetails;
